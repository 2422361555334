import { css } from '@emotion/react'
import type { ActivableProductInformationId } from '@orus.eu/dimensions'
import { spacing } from '@orus.eu/pharaoh'
import { ActivableProductInformationIdBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'

type ActivableProductInformationIdsBadgesListProps = {
  activableProductInformationIds: ActivableProductInformationId[]
}

export function ActivableProductInformationIdsBadgesList(
  props: ActivableProductInformationIdsBadgesListProps,
): JSX.Element {
  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[30]};
      `}
    >
      {props.activableProductInformationIds.map((activableProductInformationId, index) => (
        <ActivableProductInformationIdBadge key={index} activableProductInformationId={activableProductInformationId} />
      ))}
    </div>
  )
}
